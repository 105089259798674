<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import BasicInfo from "@/components/loyalty/reward-basic-info"

import {
} from "@/state/helpers";

export default {
  page: {
    title: "Rewards",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    BasicInfo
  },
  data() {
    return {
      title: "Rewards",
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      rewards:[],
      confirmDelete:false,
      reward: {},
      empty_list_config:{
          "title" : 'No se encontraron recompensas',
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      empty_config:{
          "title" : 'No se encontraron recompensas',
          "subtitle" : 'Aún no has creado recompensas. Comienza creando una nueva.',
          "buttonText" : 'Nueva Recompensa',
          icon: "fa-cubes"
      },
        showNewRewardModal: false,
        isLocal: window.config.env == 'dev',
        query: ''
    };
  },
  created(){
		this.debounceRewards = _debounce(this.searchRewards.bind(this), 1000);
	},
	mounted(){
		this.loadRewards();
  },
  methods:{
    onRemoveReward(data){
      this.reward = data;
      this.confirmDelete = true;
    },
    onConfirmRemoveRewardClicked(){
      let loader = this.$loading.show();
      this.currentPage=1;
        this.loadRewards();
      this.$notify({ type: 'success', text: this.$t('personalizations.delete_personalization_success'),title:  this.$t('personalizations.title') });
      loader.hide();
    },

		loadRewards(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
      this.rewards = [
        {
          _id: 1,
          name: 'HUGO BOSS ALIVE',
          type: 'Material',
          status: 1,
          points: 1500
        },
        {
          _id: 2,
          name: 'Cupon 100 USD',
          type: 'Cupón de descuento fijo',
          status: 1,
          points: 2400,
          limit: 100,
          stock: 21
        },
        {
          _id: 3,
          name: '2X puntos en cupón',
          type: 'Convertir puntos en cupón',
          status: 1,
          points: 5000,
          limit: null,
        },
        
        
      ]
      this.isLoading = false;
      loader.hide();
    },
    searchRewards(query){
      this.query = query;
			/*let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("memberships.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.memberships=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getContents(params).then((contents)=>{
          if(contents&& contents.data){
            this.personalizations= contents.data;
            this.rows= contents.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadPersonalizations();
      }*/
    },
		onRewardListPageClicked(){
			this.loadRewards();
		},	
    onEditRewardClicked(data){
      this.reward = data;
      this.$router.push({
            path: `/loyalty-reward?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    
    onResetSearchClicked(){
      this.query = '';
      this.loadRewards();
    },
    onNewRewardClicked(){
      this.reward = {}
      this.showNewRewardModal = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Recompensas" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceRewards($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-0"
                      @click="onNewRewardClicked">
                  <i class="mdi mdi-plus me-1"></i> Nueva Recompensa
              </button>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && rewards.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && rewards.length == 0 && query ==''" @onButtonClicked="onNewRewardClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && rewards.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Precio en puntos</th>
                      <th scope="col">Disponibilidad</th>
                      <th scope="col">Status</th>

                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in rewards" :key="data._id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditRewardClicked(data)">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                          <p class="text-muted mb-0 small interact">{{data.type}}</p>
                        </h5>
                      </td>
                      <td>
                        {{ data.points || 0  }}
                      </td>
                      <td>
                        {{ data.limit ? `${data.stock}/${data.limit}` : '♾️'  }}
                      </td>
                      <td>
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? 'Activo' : 'Inactivo' }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-play'"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditRewardClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveReward(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onRewardListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveRewardClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>Estás seguro que deseas eliminar esta recompensa?</p>
        </b-modal>
        <b-modal
          v-model = "showNewRewardModal"
          title-class="font-18"
          :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')"
          :title="reward?._id ? 'Editar Recompensa' : 'Nueva Recompensa'">
          <BasicInfo :reward="reward"></BasicInfo>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
</style>
